#aboutMe-container{
    margin-top: 280px;
}

.aboutMe-header {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    font-size: 40px;
    z-index: 1;
    position: relative;
    margin-bottom: 30px;
    margin-top: 100px;
}

.aboutMe-highlighted-text {
    position: relative;
    z-index: 1; 

}

.aboutMe-highlighted-text::before {
    content: "";
    position: absolute;
    top: 5px;  /* Adjust for better alignment */
    left: -5px; 
    right: -5px; 
    bottom: 5px;  /* Adjust for better alignment */
    background-color: #DBCDF0;  /* Updated color */
    z-index: -1;
    border-radius: 5px;  /* Soften the edges */
    transform: rotate(-0.3deg);
    box-shadow:
        2px 2px 0 #DBCDF0,  /* Updated color */
        4px 4px 0 #DBCDF0,  /* Updated color */
        -2px -2px 0 #DBCDF0,  /* Updated color */
        -4px -4px 0 #DBCDF0,  /* Updated color */
        0 4px 4px rgba(219, 205, 240, 0.4),  /* Updated color */
        0 -4px 4px rgba(219, 205, 240, 0.4),  /* Updated color */
        0 0 8px rgba(0, 0, 0, 0.2);  /* Enhanced shadow for depth */
}


.AboutMeCards-container {
    display: flex;  /* Enables Flexbox */
    justify-content: space-evenly;  /* Horizontally center items */
    align-items: center;  /* Vertically center items */
    flex-wrap: wrap;  /* Prevent wrapping onto next line */
    width: 50%;  /* Set width to 60% of parent container */
    margin: 0 auto;  /* Center the container itself */
}


.resume-container .resume-button {
    font-family: Poppins,sans-serif;
    font-weight: 500;
    display: inline-block;
    padding: 14px 26px;
    border: 2px solid black; 
    border-radius: 22px; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    text-decoration: none;
    color: black;
    transition: background-color 0.3s ease;
    background-color: #fff;
  }
  
.resume-container .resume-button:hover {
    background-color: black;
    color: #f5f5f5;
  }


  .resume-container{
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }


  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    .aboutMe-header{
        font-size: 35px; 
        /* decrease by 5px */
    }
}



  @media only screen and (max-width: 800px) {
    .aboutMe-header{
        font-size: 30px; 
        /* decrease by 10px */
    }
    .resume-container .resume-button {
        font-size: 15px;
        padding: 14px 14px;
        transform: scale(0.9);
    }

    .AboutMeCards-container {
        width: 100%;
    }

  }