#projects-container{

    margin: 180px auto;

}

.projectCards {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    margin-bottom: 50px;
    gap: 25px; 
}

#project-title {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  font-size: 40px;
  z-index: 1;
  position: relative;
  margin-bottom: 40px;
  margin-top: 0px;
}

.project-highlighted-text {
  position: relative;
  z-index: 1; 
}

.project-highlighted-text::before {
  content: "";
  position: absolute;
  top: 0px; 
  left: -5px; 
  right: -5px; 
  bottom: 0px; 
  background: rgb(154, 209, 212);  /* Updated color */
  z-index: -1;
  border-radius: 4px 2px 3px 4px;
  transform: rotate(-0.9deg);
  box-shadow:
      2px 2px 0 rgb(154, 209, 212),   /* Updated color */
      4px 1px 0 rgb(154, 209, 212),   /* Updated color */
      -2px 2px 0 rgb(154, 209, 212),  /* Updated color */
      -4px 1px 0 rgb(154, 209, 212),  /* Updated color */
      0 4px 2px rgba(154, 209, 212, 0.4),  /* Updated color */
      0 -4px 2px rgba(154, 209, 212, 0.4), /* Updated color */
      0 0 5px rgba(0, 0, 0, 0.1);  /* Unchanged */
}



.projectHeader{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.15rem;
    text-align: center;
    margin:auto
}

#cards{
    margin-top: 35px;
}

.skills-row {
    width: 45%; /*changed from 35 */
    display: flex; 
    justify-content: center; 
    flex-wrap: wrap; 
    margin: 15px auto; 
  }

  .skills-row > * { 
    margin-right: 8px; 
    margin-left: 8px;
  }
  
  .skills-row > :last-child {
    margin-right: 0; 
  }
  
  .skills-row > :first-child {
    margin-left: 0; 
  }
  

  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    .projectHeader{
        font-size: 0.95rem;
    }

    .skills-row{
        width: 70%;
    }
    #project-title{
      font-size: 35px; 
    }
  }

  @media only screen and (max-width: 800px) {
    .projectHeader{
        font-size: 0.85rem;
    }
    .skills-row{
        width: 90%;
    }

    .skills-row > * { 
        margin-right: 7px; 
        margin-left: 7px;
      }
      .projectCards {
        flex-direction: column; 
        align-items: center; 
      }
      #project-title{
        font-size: 30px; 
      }
  }

