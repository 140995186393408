#form-container {
  width: 60%;
  margin: 150px auto; 
}
#contact-title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    font-size: 40px;
    z-index: 1;
    position: relative;
    margin-bottom: 18px;
}

.contact-highlighted-text {
    position: relative; 
    z-index: 1;
}

.contact-highlighted-text::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -5px;
  right: -5px;
  bottom: 0px;
  background-color: rgba(154, 209, 212, 0.7); /* Main Color */
  z-index: -1;
  border-radius: 3px;
  transform: rotate(0deg);
  box-shadow:
      2px 1px 2px rgba(144, 199, 202, 0.6),  /* Soft horizontal shadow to the right */
      -2px 1px 2px rgba(144, 199, 202, 0.6),  /* Soft horizontal shadow to the left */
      0 2px 2px rgba(144, 199, 202, 0.4),  /* Soft vertical shadow below */
      0 -2px 2px rgba(144, 199, 202, 0.4);  /* Soft vertical shadow above */
}

.contact-description{
    text-align: center;
    opacity: 0.6;
    font-family: Poppins,sans-serif;
    font-size: 14px;
    margin-bottom: 45px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  justify-content: center;

}

.grid-item {

    padding: 6px 20px; 
    text-align: center; /* Centering the text */
    display: flex;
    justify-content: center;
  }

.grid-item.header{
    font-family: Poppins,sans-serif;
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 20px;
}

.grid-item.name-email{
  display: flex;
  flex-direction: column; 
  justify-content: space-around;
}

.grid-item.message{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grid-item.submit{
  justify-content: center;
  align-items: end;
}


span.submit-text{
  font-family: Poppins,sans-serif;
  font-weight: 600;
  color: #fff;
}

div.submit-container{
  background-color: black;
  padding: 25px 30px;
  border-radius: 40px;
  transition: all 0.15s ease-in;
}

div.submit-container:hover{
  background-color: rgb(139, 189, 192);
  transform: translateY(-5px);
  cursor: pointer;
}

i.send-arrow{
  color: #fff;
  font-size: 20px;
  margin-left: 10px;
}

i.submit-success{
  color: #fff;
  font-size: 20px;
  margin-left: 10px;  
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid royalblue;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: 0 62px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  #contact-title{
      font-size: 35px; 
      /* decrease by 5px */
  }
}



@media only screen and (max-width: 800px) {
  #form-container {
    width: 90%;
  }
  .grid-item.header{
    font-size: 17px;
  }
  #contact-title{
      font-size: 30px; 
      /* decrease by 10px */
  }
  .contact-description{
    font-size: 12px;
  }
  .form-grid {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .grid-item.address{
    margin-bottom: 50px;
  }

  .form-grid > .column-1 {
    order: 1;
  }

  .form-grid > .column-2 {
    order: 2;
  }
}
