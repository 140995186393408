.footer {
    background-color: #F3F2F4;
    text-align: center;
    font-family: 'JetBrains Mono', monospace;
    position: absolute;
    margin-top: 100px;
    padding-top: 100px;
    z-index: 10;
    width: 100%;
  }
  
  .back-to-top {
    font-weight: bold;
    text-decoration: none;
    color: #333; /* You can choose a suitable color */
  }
  
  .social-links {
    display: flex; /* Enables Flexbox */
    align-items: center; /* Aligns items along the horizontal axis */
    justify-content: center; /* Centers the items horizontally */
    z-index: 100;
  }

  .social-links img {
    height: 30px; /* Set a fixed height */
    width: auto; /* Maintain the aspect ratio */
    margin: 15px 15px;
  }
  
  
  .footer a {
    text-decoration: none; /* Removes the underline */
    color: black; /* Sets the color to black */
    font-weight: bold;
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) {
  .footer{
    padding-bottom: 16px;
    padding-top: 40px;
  }
  
}



  @media only screen and (max-width: 800px) {
    .footer{
      padding-bottom: 12px;
      padding-top: 30px;
    }
    p{
      font-size: 0.95em;
    }
    .social-links img {
      transform: scale(0.9);
  }
}

