#education-title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    font-size: 40px;
    z-index: 1;
    position: relative;
    margin-bottom: 45px;
}

.education-highlighted-text {
    position: relative; 
    z-index: 1;
}

.education-highlighted-text::before {
    content: "";
    position: absolute;
    top: 0px; 
    left: -5px; 
    right: -5px; 
    bottom: 0px; 
    background-color: #C6DEF1;  /* Updated color */
    z-index: -1;
    border-radius: 3px; 
    transform: rotate(0deg); 
    box-shadow:
        3px 1px 0 #C6DEF1,  /* Horizontal shadow to the right (Updated color) */
        6px 2px 0 #C6DEF1,  /* More pronounced horizontal shadow to the right (Updated color) */
        -3px 1px 0 #C6DEF1,  /* Horizontal shadow to the left (Updated color) */
        -6px 2px 0 #C6DEF1,  /* More pronounced horizontal shadow to the left (Updated color) */
        0 3px 2px rgba(198, 222, 241, 0.4),  /* Soft vertical shadow below (Updated color) */
        0 -3px 2px rgba(198, 222, 241, 0.4);  /* Soft vertical shadow above (Updated color) */
}


@media only screen and (min-width: 800px) and (max-width: 1200px) {
    #education-title{
        font-size: 35px; 
        /* decrease by 5px */
    }
}



  @media only screen and (max-width: 800px) {
    #education-title{
        font-size: 30px; 
        /* decrease by 10px */
    }
  }
