* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  #root {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling if needed */
    background-color: #F3F2F4;
    z-index: -1000;
  }

  
  div.css-1qzevvg{
    justify-content: center;
  }

  