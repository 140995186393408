.jobCardWrapper{
    font-family: 'Montserrat', sans-serif;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 25px;
}

.jobCardWrapper.left::after{
    content: "";
    position: absolute;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #333;
    z-index: 1;
    margin-left: -9px;
}

.jobCardWrapper.right{
    position: relative;
    left: 50%;
    align-items: start;
    padding-left: 25px;
}

.jobCardWrapper.right::before{
    content: "";
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #333;
    z-index: 1;
    margin-left: -9px;
}

.right .jobDescription{
    align-items: start;
}

.jobContent{
    display: flex;
    justify-content: end;
    align-items: center;
}

.jobDescription{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.companyName{
    font-size: 18px;

}

.jobTitle{
    font-size: 15px;
    opacity: 0.88;
    font-weight: 500;
}

.right .jobImg{
    order: -1
}
.jobLogo{
    height: 60px;
    width: 60px;
    margin: 0 10px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);  /* x-offset, y-offset, blur-radius, color */

}

i.uil-calendar-alt{
    font-size: 18px;
    margin: 0 4px;
}

.jobDuration{
    font-size: 14px;
    opacity: 0.88;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 6px;
}

.right .jobDuration{
    justify-content: flex-start;
}

@media only screen and (max-width: 800px) {
    .left .jobContent{
        text-align: right;
    }
    .right .jobContent{ 
        text-align: left;
    }
    .companyName{
        font-size: 15px;
    }
    .jobTitle{
        font-size: 12px;
    }
    .jobLogo{
        display: none;
    }
    i.uil-calendar-alt{
        font-size: 12px;
    }
    .jobDuration{
        font-size: 10px;
        margin:0;
    }

  }