.scroll-up-button {
    position: fixed;
    right: 50px; /* Move into view */
    bottom: -100px; /* Initially out of view */
    background-color: black;
    opacity: 0.65;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 45%;
    transition: bottom 0.2s ease-out; /* Animation */
    z-index: 1000;
  }
  
  .scroll-up-button.show {
    bottom: 40px; /* Initially out of view */
}
  

  i.uil-arrow-up{
    font-size: 30px;
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    .scroll-up-button {
        display: none;
    }
}



  @media only screen and (max-width: 800px) {
    .scroll-up-button {
        display: none;
    }
  }