.scroll-container {
    display: flex;
    align-items: center; /* Vertically aligns items in the center */
    justify-content: center; /* Horizontally aligns items in the center */
    cursor: pointer;
    transition: transform 0.3s ease;  /* Smooth transition for the transform property */
    margin-top: 150px;
    animation: fadeIn 1.3s ease-in-out;
}

.scroll-container:hover {
    transform: translateY(-3.5px);  /* Shifts the container up by 10px */
}

.scroll-icon {
    font-size: 5em;  /* Makes the icon 5 times bigger */
}

.scroll-text {
    margin-right: 0px;  
    font-size: 16px; 
    font-weight: 500;
    font-family: Poppins,sans-serif;
}

.mouse{
    font-size: 40px;
}
.scroll-arrow {
    font-size: 23px;  /* Adjust the font size as needed */
    position: relative;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) {

  }

  @media only screen and (max-width: 800px) {
    .scroll-text{
        font-size: 14px;
        margin: 0 3px;
    }
    .mouse{
        font-size: 30px;
    }

  }