.longCard-container{
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 5px 5px; 
    width: 350px;
    height: 480px;
    margin-left: 25px;
    margin-right: 25px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    transition: transform 0.15s ease-in-out;
    display: block;
    overflow: hidden;
    background-color: #fbf7f5;
}


.longCard-header-color{
    display: block;
    padding-bottom: 45px;
}

.longCard-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    margin-left: 17px;
    margin-bottom: 18px;
    margin-top: 15px;
    text-align: left;
    justify-content: space-between;
    font-weight: bolder;

}

.longCard-title:hover{
    cursor: pointer;
}

.longCard-title .longTitle-text {
    background-color: #c9d4f2;
    padding: 2px 3.2px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    text-decoration: none; 
    color: inherit; 
    cursor: pointer;
}

.longCard-title .longTitle-text:hover {
    background-color: #48B6D1;
}

.longCard-description{
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 16px;
    font-weight: 400;
    margin-left: 17px;
    margin-bottom: 20px;
    padding: 2px 3.2px;
    padding-right: 8px;
    word-wrap: break-word; 
    overflow-wrap: break-word; /* Same as word-wrap */
    white-space: normal; 
}

.longCard-image {
    width: 100%;
    height: 200px; 
    display: block; /* Display block to allow width to work correctly */
    overflow: hidden;
    display: block;
    object-fit: cover;
}

.card-skills{
    margin-left: 13px;

}


  @media only screen and (max-width: 800px) {
    .longCard-container{
        width: 310px;
        height: 485px;
        margin-bottom: 40px;
    }
    .longCard-title {
        font-size: 24px;
        max-width: 250px;
    }
    .longCard-description{
        font-size: 15px;
        margin-left: 17px;
        margin-right: 20px;
    }

  }