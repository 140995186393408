.typing-container {
    opacity: 0; /* Set initial opacity to 0 */
    animation: fadeIn 2s ease forwards;
    font-size: 18px;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    .typing-container{
      font-size: 0.95em;
    }
  }

  @media only screen and (max-width: 800px) {
    .typing-container{
      font-size: 0.9em;
    }
  }

