

#experience-header {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    font-size: 40px;
    z-index: 1;
    position: relative;
    margin-bottom: 40px;
    margin-top: 100px;
}

.experience-highlighted-text {
    position: relative;
    z-index: 1; 
}


.experience-highlighted-text::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -5px;
  right: -5px;
  bottom: 0px;
  background-color: rgba(240, 128, 128, 0.7); /* Main Color */
  z-index: -1;
  border-radius: 3px;
  transform: rotate(-0.4deg);
  box-shadow:
      2px 1px 2px rgba(230, 118, 118, 0.6),  /* Soft horizontal shadow to the right */
      -2px 1px 2px rgba(230, 118, 118, 0.6),  /* Soft horizontal shadow to the left */
      0 2px 2px rgba(230, 118, 118, 0.4),  /* Soft vertical shadow below */
      0 -2px 2px rgba(230, 118, 118, 0.4);  /* Soft vertical shadow above */
}

.experience-content{
  margin: 0 auto;
  width: 85%;
  position: relative;
}

.experience-content::after{
  content: "";
  position: absolute;
  top: 0px;
  width: 3px;
  height: 100%;
  background-color: rgba(0,0,0,.9);
  left: 50%;
  z-index: -1;
  margin-left: -3px;

}

  
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  #experience-header{
      font-size: 35px; 
      /* decrease by 5px */
  }

}



@media only screen and (max-width: 800px) {
  #experience-header{
      font-size: 30px; 
      /* decrease by 10px */
  }
  .experience-content{
    width: 95%;
  }
}

