.input-container {
    position: relative;
    display: inline-block;
  }
  
  .label {
    position: absolute;
    top: -10px;
    left: 45px;
    background-color: #F3F2F4;
    padding: 0 5px;
    z-index: 1;
    font-family: Poppins,sans-serif;
    font-size: 14px;

  }
  
  .input {
    width: 350px;
    height: 60px;
    border: 2px solid #000;
    border-radius: 30px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    background-color: #F3F2F4;
    opacity: 0.5;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
  }

  .input.message{
    height: 170px;
    padding-top: 14px;
    padding-bottom: 14px;
    vertical-align: top;
  }
  
  .input-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 20px;
    background-color: #F3F2F4;
    z-index: 0;
  }
  
  .input:focus,
  .message:focus {
    border-color: #1A8D4D;
    outline: none;
    border-width: 3px;
  }

  .invalid-border {
    border: 2px solid #FF4136;  /* Replace with your unconventional red shade */
  }
  
  /* For shaking effect */
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  }
  
  .shake {
    animation: shake 0.5s ease-in-out;
  }

  @media only screen and (max-width: 800px) {
   .input-container{
    margin-bottom: 20px;
   }
   .label {
    left: 25px;
   }

   .input {
    width: 300px;
    font-size: 14px;
   }
  }