.contactCard-container{
    background-color: #fff;
    position: relative;  /* Make it relative to position the subtitle absolutely */
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0,0,0,.25);
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 35px;
    height: 170px;
    width: 320px;
    padding: 10px 25px;
    font-family: 'Poppins', sans-serif;
    display: flex;  /* Flexbox for centering */
    align-items: center;  /* Vertical centering */
    justify-content: end;
    flex-direction: column;
    transition: all 0.3s ease; /* Transition for smoothness */

}

.contactCard-container:hover {
    transform: translateY(-5px);  
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); 
}

i.logo{
    font-size: 25px;
}

.contactCard-title{
    font-size: 15px;
    margin-top: 6px;
}

.contactCard-subtitle{
    font-size: 14px;
    opacity: 0.65;
}

i.arrow{
    font-size: 20px;
}

.arrow-container{
    padding: 8px;
    transition: all 0.3s ease;
    margin-top: 10px;
}

.arrow-container:hover{
    cursor: pointer;
    transform: translateX(7px);
}

