.code-interface {
    background: linear-gradient(to right, #bfbfbf, #e5e5e5); /* change back to rgb(40, 44, 52) if anyth */
    border-radius: 10px;
    box-shadow: 15px 23px 12px rgba(0, 0, 0, 0.2);
    padding-top: 10px;
    width: 60%;
    margin: auto;
    color: rgb(248, 248, 242);
    font-family: 'Courier New', monospace;
    margin-top: 30px;
    margin-bottom: 130px
  }
  
  .mac-buttons {
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;

  }
  
  .button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .button.red { background-color: rgb(255, 95, 86); }
  .button.yellow { background-color: rgb(255, 189, 46); }
  .button.green { background-color: rgb(39, 201, 63); }

  
  pre.code-block {
    white-space: pre-wrap;
    padding: 20px 30px 30px 30px;
    font-size: 115%;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  /* width: 60%; */
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    .code-interface{
      width: 70%;
    }
    pre.code-block{
      font-size: 100%;
    }

  }
  
  @media only screen and (max-width: 800px) {
    .code-interface{
      width: 85%;
    }
    pre.code-block{
      font-size: 80%;
    }

  }