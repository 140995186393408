.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0; 
  background-color: transparent;
  transition: background-color 0.5s cubic-bezier(0.4, 0, 0.2, 1), color 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 18px; 
  z-index: 100;
}

.navbar.scrolled {
    background-color: #F1E4C0;
}


.navbar .left {
  cursor: pointer;
  margin: 0 30px;
}

div.menu-container{
  display: none;
}

.center {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.item {
    margin: 0 30px;
}

.center .item {
  position: relative;
  cursor: pointer;
  display: block;
}

.center .menu-icon {
  display: none;
}

.center .item::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 50%;
  background-color: currentColor; 
  transition: width 0.3s ease, left 0.3s ease; 
}

.center .item:hover::before,
.center .item.active::before,
.center .item.project-active::before {
  width: 100%;
  left: 0;
}

.navbar.scrolled .center .item::before {
    background-color: black; 
}


.navbar a,
.navbar div {
    color: inherit; 
    text-decoration: none;
    font-family: 'Inconsolata', monospace;
}

.navbar .resume-button {
  display: inline-block;
  padding: 8px 16px;
  border: 2px solid black; 
  border-radius: 8px; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  text-decoration: none;
  color: black;
  transition: background-color 0.3s ease;
}

.navbar .resume-button:hover {
  background-color: black;
  color: #f5f5f5;
}

.navbar.scrolled .resume-button {
  border-color: #f5f5f5; 
  color: #f5f5f5;
}

.navbar.scrolled .resume-button:hover {
  background-color: #f5f5f5;
  color: black;
}

.homeButton {
  height: 45px; 
  width: 45px;  
}


@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar .left,
.navbar .center .item,
.navbar .right{
  animation: slideFadeIn 0.8s ease forwards;
}



@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .item {
    font-size: 0.95em; 
    margin: 0 20px; 
  }
  .right{
    margin-right: 35px;;
  }

}

/* For screen width below 800px */
@media only screen and (max-width: 800px) {
  .center .item {
    display: none;
  }
  .center .menu-icon {
    display: block;
    font-size: 40px;
    margin-right: 15px;
  }

  .menu-icon:hover{
    cursor: pointer;
  }

div.menu-container{
  display: none;
}
  
div.menu-container.visible{
    display: block;
    position: absolute;
    bottom: -183px;
    right: 18px;
    border-radius: 15px;
    width: 200px;
    overflow: hidden;
  }

  div.menu-item{
    text-align: center;
    padding: 10px 20px;
    background-color:#D3D3D3;
    font-size: 16px;
  }

  div.menu-item:hover{
    background-color:#A9A9A9;
    cursor: pointer;
  }
  .navbar .left{
    margin-left: 15px;
    margin-right: 0px;
  }

}

