.greeting-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 200px;
  padding: 100px 0 20px 0; /* Added top padding to separate from navbar */
  animation: expandFromNothing 1.5s ease forwards 0.3s; 
  opacity: 0; 
  overflow: hidden;
  position: relative;

}

.greeting-text {
  position: relative;
}

.welcome-text {
  font-size: 25px; /* Updated font size */
  display: block;
  font-family: 'Open Sans', sans-serif;
}

.name-text {
  font-size: 76px; /* Updated font size */
  display: block;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: rgb(50,50,50);
  z-index: 1;
}

@keyframes slideIn {
  0% {
    left: -400px; 
  }
  100% {
    left: 0; 
    opacity: 1;
  }
}
.name-text::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 30px; /* Increased thickness to three times the original value */
  bottom: -4px; /* Adjusted position to underline the bottom 25% of the name */
  left: 0;
  background-color: rgba(255, 215, 0, 0.75);
  transform: skewX(-10deg);
  z-index: -1; /* Position the highlight below the text */
  animation: slideIn 0.6s ease-in-out 0.8s forwards;
  opacity: 0;
}

@keyframes expandFromNothing {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  30% {
    opacity: 1;
    transform: scale(1.15); /* Expand out (overshoot) */
  }
  60% {
    transform: scale(0.95); /* Shrink back (contraction) */
  }
  100% {
    transform: scale(1); /* Expand to normal position */
    opacity: 1;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  span.welcome-text{
    font-size: 22px;
  }

  span.name-text{
    font-size: 66px;
  }
}

@media only screen and (max-width: 800px) {
  span.welcome-text{
    font-size: 19px;
  }

  span.name-text{
    font-size: 60px;
  }

  .name-text::after {
    height: 20px; 
    bottom: 4px;

  }
}