.aboutMeCard-container{
    background-color: #fff;
    position: relative;  /* Make it relative to position the subtitle absolutely */
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0,0,0,.25);
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 35px;
    height: 130px;
    width: 180px;
    padding: 10px 25px;
    font-family: 'Poppins', sans-serif;
    display: flex;  /* Flexbox for centering */
    align-items: center;  /* Vertical centering */
    justify-content: space-around;
    flex-direction: column;
    transition: all 0.3s ease; /* Transition for smoothness */
}

.aboutMeCard-container:hover {
    transform: translateY(-5px);  /* Moves the card up by 10px */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);  /* More pronounced shadow */
}

.aboutMeCard-title {
    margin: 0;  /* Remove default margins */
    font-size: 22px;
}

.aboutMeCard-subtitle {

    font-weight: 400;
    font-size: 13px;
    padding-bottom: 5px;
    opacity: 0.6;
}

.logo{ 
    text-align: center;
}

i{
    margin: 0;
    padding: 0;
}



@media only screen and (min-width: 800px) and (max-width: 1200px) {

}



  @media only screen and (max-width: 800px) {
    .aboutMeCard-container{
        transform: scale(0.85);
    }

    .aboutMeCard-container:hover{
        transform: scale(0.9);
    }

  }