.wideCard-container{
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 5px 5px; 
    width: 60%;
    height: 250px;
    margin: 30px auto;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    transition: transform 0.15s ease-in-out;
    display: block;
    overflow: hidden;
    background-color: #fbf7f5;
}

.wideCard-container:hover{
    transform: translate(-10px, -7px); 
}

.wideCard-header-color{
    display: block;
    padding-bottom: 45px;
}

.wideCard-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    margin-left: 20px;
    margin-bottom: 18px;
    margin-top: 10px;
    text-align: left;
    display: flex; /* Add flex display */
    justify-content: space-between;
    font-weight: bolder;
}

.wideCard-title .title-text {
    background-color: #c9d4f2;
    padding: 2px 3.2px;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
}

.wideCard-title .title-text:hover {
    background-color: #48B6D1;
}

.wideCard-description{
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    margin-left: 20px;
    padding: 2px 3.2px;
}

.time-frame {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    margin-right: 20px;
    text-align: right;
    font-weight: 500;
}

.wideCard-description ul {
    list-style-type: disc; 

}

.wideCard-description li {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    margin-left: 20px;
    padding: 2px 3.2px;
    font-size: 17px;
}


@media only screen and (min-width: 800px) and (max-width: 1200px) {
.wideCard-container{
    width: 70%
}
.wideCard-title .title-text {
    font-size: 24px;

}
span.time-frame {
    font-size: 15px;
}

.wideCard-description li {
    font-size: 15px;
}
}



@media only screen and (max-width: 800px) {
.wideCard-container{
    width: 85%
}
.wideCard-title {
    margin-left: 10px;
    flex-direction: column; /* Stack the children vertically */
    align-items: flex-start; /* Align children to the start */

}
.wideCard-title .title-text {
    border-radius: 5px;
    font-size: 19px;
    margin-bottom: 7px;
}
.time-frame {
    font-size: 10px;
    margin-left: 5px;
    }

.wideCard-description li {
    font-size: 12px;
    margin-left: 5px;
}
}
