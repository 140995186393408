.carousel-main{

}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .carousel-main{
    transform: scale(0.9);
    position: relative;
    bottom: 20px;
} 
}



  @media only screen and (max-width: 800px) {
    .carousel-main{
        transform: scale(0.85);
        position: relative;
        bottom: 20px;
    }
  }