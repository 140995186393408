.skillC-name{
    font-family: 'JetBrains Mono', monospace;
    font-size: 15px;
    display: inline-block;
    padding: 3px 4px;
    border-radius: 6px;
    opacity: 0.55;
    transition: transform 0.3s ease, opacity 0.3s ease; 
    margin-bottom: 10px;
}

.skillC-name:hover{
    opacity: 1;
    cursor: pointer;
}

.skillC-name.selected {
    opacity: 1; 
    transform: scale(1.1);
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
.skillC-name{
    font-size: 13px;
}
  }

  @media only screen and (max-width: 800px) {
    .skillC-name{
    font-size: 12px;
    }
  }
